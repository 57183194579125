<template>
  <fragment>
    <v-expansion-panels
      readonly
      :value="mappedVal"
      class="px-sm-8 px-4 mb-2">
      <v-expansion-panel>
        <v-expansion-panel-header hide-actions>
          <div
            v-if="!isExpanded"
            class="v-expansion-panel-header__container">
            <div
              v-for="headerItem in columnsToSummary"
              :key="headerItem.colTitle"
              class="pa-1">
              <b>{{ headerItem.colTitle }}:</b> {{ headerItem.value }}
            </div>
          </div>
        </v-expansion-panel-header>
        <div class="d-flex justify-end mt-1">
          <v-btn
            class="select-view-container__edit-button lightGrey--text recalc-button mt-2 mb-4"
            outlined
            rounded
            color="darkGrey"
            @click="recalculateBudget">
            Recalculate Budget
          </v-btn>
        </div>
        <v-expansion-panel-content>
          <v-row class="budget-summary">
            <v-col
              v-for="item in columnsToSummary"
              :key="item.colTitle"
              cols="12"
              sm="6"
              md="3">
              <h4 class="budget-summary__column-title">
                {{ item.colTitle }}:
              </h4>
              <ul class="budget-summary__list">
                <li
                  v-for="subColItem in item.colValues"
                  :key="subColItem.title"
                  class="budget-summary__list-item">
                  {{ subColItem.title }}: <br>
                  {{ subColItem.value }}
                </li>
              </ul>
            </v-col>
            <v-col
              cols="12"
              md="6">
              <h4 class="budget-summary__column-title mb-0">
                Summary:
              </h4>
              <div class="d-flex justify-space-between mt-11">
                <PieChart
                  v-if="chartData.length"
                  class="pie-chart"
                  width="100%"
                  :height="$vuetify.breakpoint.mdAndUp ? '250' : '150'"
                  :type-percentage="chartData" />
                <div
                  v-if="chartData.length"
                  class="d-flex flex-column justify-center mb-10 pr-2">
                  <span
                    :style="{color: overConditionObj.criteria === 'Over' ? 'red' : 'var(--v-green-base)'}"
                    class="font-weight-bold no-wrap">
                    {{ overConditionObj.title }}
                  </span>
                  <span class="ml-1">
                    {{ unitTitle }}
                  </span>
                  <span class="font-weight-bold">
                    {{ overConditionObj.value }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <ActionBlock
      class="expanded-v2--title"
      :value="isExpanded"
      show-double-arrows
      @change="changeVal" />
  </fragment>
</template>
<script>
import PieChart from '@/components/App/AppPieChart';
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';
const RECALC_DELAY = 5000;
export default {
  name: 'ProjectDetailsSummaryContainer',
  components: {
    PieChart,
    ActionBlock: () => import('../ProjectDetailsSummaryActionBlock'),
  },
  props: {
    columnsToSummary: {
      type: Array,
      default: () => [],
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    overConditionObj: {
      type: Object,
      default: () => {},
    },
  },
  data: () => {
    return {
      value: false,
    };
  },
  computed: {
    ...mapState('ProjectDetails', ['detailProjectData']),
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    unitTitle() {
      const { unitTitle } = this.overConditionObj;
      return unitTitle ? `(${unitTitle})` : '';
    },
    mappedVal() {
      const { value: val } = this;
      return val ? 0 : false;
    },
    isExpanded() {
      return Number.isInteger(this.mappedVal);
    },
  },
  methods: {
    ...mapMutations(['spinner', 'openSnackBar']),
    ...mapActions('ProjectAggregation', ['recalculateAggregation']),
    changeVal({ notAllowToTag, event }) {
      const { localName } = event?.target ?? {
      };
      if (notAllowToTag && notAllowToTag === localName) return;
      this.value = !this.value;
    },
    async recalculateBudget() {
      this.spinner(true);
      // Delay recalc so that any recently updated row can get processed.
      await new Promise(r => setTimeout(r, RECALC_DELAY));

      try {
        const variables = {
          projectId: this.detailProjectData.id,
          scheduleId: this.scheduleId,
        };
        await this.recalculateAggregation(variables);
        this.openSnackBar({
          title: 'Budget Recalculation Complete',
        });
      } catch (e) {
        console.error(e);
        this.openSnackBar({
          title: 'Error with Budget Recalculation',
          text: e,
        });
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  .budget-summary {
    &__column-title {
      margin-bottom: 45px;
    }
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      &-item {
        margin-bottom: 20px;
      }
    }
    .pie-chart {
      width: calc(100% - 180px);
    }
  }
  .v-expansion-panel {
    &-content {
      overflow: hidden auto;
      padding: 12px;
      height: auto;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        height: 200px;
      }
    }
    &-header {
      &__container {
        display: flex;;
        justify-content: space-between;
        background-color: #fff;
        flex-wrap: wrap;
      }
      padding: 0;
      min-height: 0px !important;
    }
    &::before {
      box-shadow: none;
    }
    ::v-deep &-content__wrap {
      padding: 0;
    }
  }
  .expanded-v2--title {
    width: 100%;
    background: rgba(113, 214, 224, 0.2);
    text-align: center;
    cursor: pointer;
    margin-bottom: 12px;
    &:hover {
      opacity: 0.8;
    }
  }
  .recalc-button {
    height: 29px !important;
    ::v-deep.v-btn__content {
      font-size: 14px !important;
    }
  }
</style>
