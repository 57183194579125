var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('CollectionsCreateProductDialog',{attrs:{"local-product":_vm.product,"is-allow-comparison":false,"status-dialog":_vm.statusDialogProduct,"is-status-dialog-from-state":false},on:{"update:statusDialog":function($event){_vm.statusDialogProduct=$event},"update:status-dialog":function($event){_vm.statusDialogProduct=$event}}}),_c('TableLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowTable),expression:"isShowTable"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],ref:"v-data-table--schedule",class:[
      _vm.freezeImage,
      _vm.imageOverlay,
      ("v-data-table--schedule__" + _vm.selectedViewCriteria),
      _vm.sharedProjectContainer,
      _vm.showActualWidth && 'v-data-table--schedule__no-overflow',
      'pl-6'
    ],attrs:{"custom-id":_vm.customId,"headers":_vm.sor.wrap(_vm.tableHeaders),"items":_vm.sor.wrap(_vm.dataWithItemClass, 'scheduleData')},on:{"hook:mounted":_vm.onTableMountedHook},scopedSlots:_vm._u([{key:"thead",fn:function(ref){
    var headers = ref.headers;
return [_c('TableHeader',{attrs:{"project-id":_vm.projectId,"headers":headers,"disabled-edit-mode":_vm.disabledEditMode,"table-height":_vm.tableHeight},on:{"onDragEnd":function($event){return _vm.$emit('onDragEnd', $event)},"add-table-class":_vm.addTableClass,"remove-table-class":_vm.removeTableClass}})]}},{key:"cellItem",fn:function(ref){
    var header = ref.header;
    var index = ref.index;
    var item = ref.item;
return [(_vm.isVisibleCell(item, header, index))?_c('TableCellBlock',{ref:_vm.getRefOfCell(item.id, header.value),attrs:{"header":header,"index":index,"item":item,"table-id":_vm.scheduleId,"disabled-edit-mode":_vm.disabledEditMode,"rendered-draggable-index":_vm.renderedDraggableIndex,"show-project-detail-actions":!_vm.isHiddenProjectDetailActions && !!item.order,"data":_vm.data,"transform-images-to-data":_vm.transformImagesToData,"is-unsuccessful-payment":_vm.isUnsuccessfulPayment,"bordered-cell":_vm.borderedCell({
          currentRowId: item.id,
          columnName: header.value,
          columnType: header.column.type
        })},on:{"setMenuItem":_vm.setMenuItem,"setMultipleShiftCells":_vm.setMultipleShiftCells,"setMultipleCtrlCells":_vm.setMultipleCtrlCells,"updateCell":_vm.updateCell,"copyContent":function (isSelectedCell) { return _vm.copyContent({
          selected: isSelectedCell,
          columnType: header.column.type,
          columnName: header.value,
          rowId: item.id }); },"pasteContent":function($event){return _vm.pasteContent({
          columnType: header.column.type,
          oldVal: item[header.value],
          columnName: header.value})},"showExpandedDialog":function($event){return _vm.showExpandedDialog(item)}}}):_vm._e()]}}])}),_c('TableActions',{attrs:{"show-activator":false,"item":_vm.selectedItem,"position-x":_vm.positionX,"position-y":_vm.positionY,"value":_vm.showActionsCard},on:{"update:item":function($event){_vm.selectedItem=$event},"update:value":function($event){_vm.showActionsCard=$event},"openProductDialog":_vm.openProductDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }