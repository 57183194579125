<template>
  <div class="project-details-title">
    <div
      v-if="$vuetify.breakpoint.lgAndUp"
      class="back-icon"
      @click="$router.push({ name: 'projects' })">
      <img
        src="@/assets/icons/arrow-left-icon.svg"
        height="30"
        width="30">
    </div>
    <div
      v-else
      class="back-icon order-2"
      @click="$emit('toggleNav')">
      <img
        src="@/assets/icons/menu-icon.svg"
        height="24"
        width="24">
    </div>

    <div class="px-6 flex-grow-1 d-flex justify-space-between align-center">
      <!-- left -->
      <div
        v-if="showHeaderElements || shareLink"
        class="d-flex gap-3">
        <!-- projects icon -->
        <ColoredIcon :share-link="shareLink" />

        <!-- project name -->
        <div class="d-flex flex-column justify-center gap-1">
          <div class="d-flex align-center gap-2 fs-18 font-fam-poppins lh-1">
            <span
              v-if="detailProjectData && detailProjectData.number"
              class="darkGrey--text">
              {{ detailProjectData.number + ' ' }}
            </span>
            <span
              v-if="detailProjectData && detailProjectData.projectName"
              class="black--text">{{ detailProjectData.projectName }}</span>
          </div>

          <!-- show version -->
          <span
            v-if="isVersionMode"
            class="fs-11 red--text text-uppercase">
            Current Version: {{ versionTitle }}
          </span>
          <span
            v-else-if="detailProjectData && detailProjectData.projectLastModified"
            class="fs-11 darkGrey--text text-uppercase">
            Last modified: {{ $formatProjectDates(detailProjectData.projectLastModified) }}
          </span>
        </div>
      </div>

      <!-- right -->
      <div class="d-flex align-center gap-3">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
  mapMutations, mapState,
} from 'vuex';
export default {
  name: 'ProjectDetailsTitle',
  components: {
    ColoredIcon: () => import('@/components/ProjectDetails/ProjectDetailsSetColoredIcon'),
  },
  props: {
    detailProjectData: {
      type: Object,
      default: null,
    },
    needShowControls: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      default: null,
    },
    userInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState(['role']),
    ...mapGetters('ProjectVersions', ['isVersionMode', 'versionTitle']),
    shareLink() {
      return ['project-library'].includes(this.$route.name);
    },
    showHeaderElements() {
      return ['schedule-library'].includes(this.$route.name) && this.detailProjectData !== null && this.userInfo;
    },
    showSettingsIcon() {
      return this.detailProjectData?.status === 'active';
    },
  },
  methods: {
    ...mapMutations(['showProjectInfoModalAction', 'showShareModalAction']),
  },
};
</script>
<style lang="scss" scoped>
.project-details-title {
  background-color: var(--v-lightGrey-base);
  min-height: 75px;
  display: flex;
  justify-content: space-between;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    min-height: 85px;
  }

  .lh-1 {
    line-height: 1;
  }

  .back-icon {
    background-color: var(--v-black-base);
    cursor: pointer;
    display: grid;
    padding: 10px 30px;
    place-items: center;
  }
}
</style>