import {
  mapGetters, mapActions, mapState,
} from 'vuex';
import { hasEditorAccess } from '@/utils';
import productHeaders from '@/constants/productHeaders';
import { SCHEDULE_UPDATE_CELL } from '@/constants/undoActions';
import ScheduleCells from '@/services/graphql/scheduleCells';
import {
  getNextFullRowVersion,
  getNextRowVersion,
  isIncorrectVersion,
  isNeedUpdateIncorrectVersion,
  isNeedRetryUpdateVersion,
} from '@/utils/manageGetRows';
export default {
  data() {
    return {
      productHeaders,
    };
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', [
      'selectedCells',
      'scheduleSessionUuid',
    ]),
    ...mapState({
      cellsVersions: state => state.ScheduleRows.cellsVersions,
    }),
    ...mapGetters({
      isSelectedCell: 'ProjectDetailsTableSchedule/isSelectedCell',
      isReadOnlyCell: 'ProjectDetailsTableSchedule/isReadOnlyCell',
      isUnsuccessfulPayment: 'UserProfile/isUnsuccessfulPayment',
      copiedContent: 'ProjectDetailsTableSchedule/copiedContent',
      canModifyProjectDocuments: 'UserRoles/canModifyProjectDocuments',
      userId: 'userId',
    }),
    textNotSupportedMultiple() {
      return 'This operation is not supported with multiple selections.';
    },
    filteredSelectedCells() {
      const { selectedCells, copiedContent } = this;
      const cellsPaste = [];
      const lengthSellectedCells = selectedCells.length;
      const lengthCopied = copiedContent.length;
      for (let i = 0; i < lengthSellectedCells; i++) {
        const selectedCell = selectedCells[i];
        // if сells not enough for each pairs of copiedCells ,it break loop
        if (((i + lengthCopied) > lengthSellectedCells) && Number.isInteger((i + lengthCopied) / lengthCopied)) break;
        cellsPaste.push(selectedCell);
      }
      return cellsPaste.sort((a, b) => a.index - b.index );
    },
    allowedForAppropriateRoles() {
      return hasEditorAccess(this.role) && !!this.userId;
    },
    notSupportMultiplePaste() {
      const { selectedCells, copiedContent } = this;
      const indexSelectedCells = selectedCells.map(e => e.index).sort((a, b) => a - b);
      const multipleCopiedCells = copiedContent.length > 1;
      const comparisonToLastElem = indexSelectedCells[0] + (indexSelectedCells.length - 1);
      const lastElem = indexSelectedCells[indexSelectedCells.length - 1];
      return multipleCopiedCells && comparisonToLastElem !== lastElem;
    },
  },
  methods: {
    ...mapActions({
      setCopiedContent: 'ProjectDetailsTableSchedule/setCopiedContent',
      setUndoActions: 'UndoActions/setUndoActions',
      updateCellHandle: 'ScheduleRows/updateCell',
      updateCellVersion: 'ScheduleRows/updateCellVersion',
      handleError: 'handleError',
    }),
    allowSpinnerOnUpdate({ columnName, columnType, ev }) {
      if (this.isRemovingProductReference(columnType, ev)) return false;

      return this.isAttachmentCell(columnType) || this.isTypeCell(columnName) || this.isFieldProduct(columnType);
    },
    /**
    * Checks whether local row update is allowed based on the column type.
    * - Product fields + Attachment cells are not allowed.
    * - Exception is product reference if we are removing it.
    *
    * @param {Object} options - An object containing the columnType property.
    * @param {string} options.columnType - The type of the column.
    * @param {Object} options.ev - Event triggering the update.
    * @returns {boolean} Returns true if local row update is not allowed, otherwise false.
    */
    disallowLocalRowUpdate({ columnType, ev }) {
      if (this.isRemovingProductReference(columnType, ev)) return false;

      return this.isAttachmentCell(columnType) || this.isFieldProduct(columnType);
    },
    isTypeCell(columnName) {
      return columnName === productHeaders.TYPE_DESIGNATION;
    },
    isAttachmentCell(columnType) {
      return columnType === 'ARRAY_AIRTABLE_IMAGE';
    },
    isFieldProduct(columnType) {
      return columnType.includes('FIELD_PRODUCT');
    },
    isRemovingProductReference(columnType, ev) {
      return columnType === 'FIELD_PRODUCT_REFERENCE' ? !ev?.value : false;
    },
    getColValForAppropriateRow({ id, col }) {
      return this.data.find(item => item.id === id)?.[col];
    },
    getFormattedCellValue({ value, columnType }) {
      let calcValue = null;
      if (this.isFieldProduct(columnType)) {
        calcValue = Array.isArray(value)
          ? value.map(item => JSON.stringify(item))
          : !value ? '' : JSON.stringify(value);
      } else {
        calcValue = Array.isArray(value)
          ? value.map(item => item.id ? item.id
            : item) : this.formatValue({
            value: value, columnType,
          });
      }
      return calcValue;
    },
    initUndoActions(cellsForUndoActions, columnType, columnName) {
      const { cellsForPasting, filteredSelectedCells } = this;
      const undoActions = [];
      const isMultiple = filteredSelectedCells.length;
      for (let i = 0; i < cellsForUndoActions.length; i++) {
        let { col, rowId } = cellsForUndoActions[i];
        rowId = isMultiple ? rowId : cellsForPasting(columnName)[i];
        const objForUndo = {
          value: this.getFormattedCellValue({
            value: this.getColValForAppropriateRow({
              id: rowId,
              col,
            }),
            columnType,
          }),
          col,
          row: {
            rowId,
            scheduleId: this.scheduleId,
          },
          projectId: this.$route.params.id,
          action: SCHEDULE_UPDATE_CELL,
          allowSpinnerOnUpdate: this.allowSpinnerOnUpdate({
            columnName, columnType,
          }),
          disallowLocalRowUpdate: this.disallowLocalRowUpdate({
            columnType,
          }),
        };
        undoActions.push(objForUndo);
      }
      this.setUndoActions(undoActions);
    },
    async pasteContent({ columnType, columnName, oldVal }) {
      const { filteredSelectedCells, copiedContent, cellsForPasting, notSupportMultiplePaste, openSnackBar, textNotSupportedMultiple: text } = this;
      if (notSupportMultiplePaste) {
        openSnackBar({
          text,
        });
        return false;
      }
      const copiedCutContent = copiedContent.slice(0, cellsForPasting(columnName).length);
      const isMultiple = filteredSelectedCells.length;
      const cellsForPutting = isMultiple ? filteredSelectedCells : copiedCutContent;
      let indexCopied = 0;
      this.initUndoActions(cellsForPutting, columnType, columnName);
      await Promise.all(cellsForPutting.map(async ({ col, rowId, value }, i) => {
        if (!copiedContent[indexCopied] && isMultiple) indexCopied = 0;
        value = isMultiple ? copiedContent[indexCopied].value : value;
        const calcValue = this.getFormattedCellValue({
          value, columnType,
        });
        if (isMultiple) indexCopied += 1;
        rowId = isMultiple ? rowId : cellsForPasting(columnName)[i];
        await this.updateCell({
          ev: {
            [Array.isArray(calcValue) ? 'values' : 'value']: calcValue,
          },
          col,
          rowId,
          isPasteAction: true,
          columnName,
          columnType,
          oldVal,
        });
      }));
    },
    /**
 * Update a cell's value in the schedule.
 *
 * @param {object} options - Options for updating the cell.
 * @param {object} options.ev - The event triggering the update.
 * @param {string} options.col - The column identifier.
 * @param {string} options.rowId - The row identifier.
 * @param {boolean} [options.isPasteAction=false] - Indicates if it's a paste action.
 * @param {boolean} [options.canUsePasteAction=true] - Indicates if paste action is allowed.
 * @param {boolean} [options.useUpdateLocalValue=true] - Indicates whether to update the local value (default: true).
 * @param {string} options.columnName - The column name.
 * @param {string} options.columnType - The column type.
 * @param {any} options.oldVal - The old value.
 */
    async updateCell({
      ev,
      col,
      rowId,
      isPasteAction = false,
      canUsePasteAction = true,
      useUpdateLocalValue = true,
      lastFailedCellVersion,
      isRetryVersionAction = false,
      columnName,
      columnType,
      oldVal: value,
    }) {
      // Destructure properties for cleaner code
      const {
        $route: route,
        spinner,
        scheduleId,
        handleError,
        openSnackBar,
        scheduleSessionUuid,
        updateCellVersion,
      } = this;
      let nextRowVersion = null;
      let allowSpinnerOnUpdate = false;
      try {
        if (isRetryVersionAction) {
          const isRetry = isNeedRetryUpdateVersion({
            rowId, columnName, cellsVersions: this.cellsVersions, lastFailedCellVersion,
          });
          if (!isRetry) return;
        }
        // Check if a spinner is allowed on the update
        allowSpinnerOnUpdate = this.allowSpinnerOnUpdate({
          columnName, columnType, ev,
        });
        // Check if local row update is disallowed for the given columnType
        const disallowLocalRowUpdate = this.disallowLocalRowUpdate({
          columnType,
          ev,
        });

        // Set undo actions if not a paste action and paste action is allowed
        if (!isPasteAction && canUsePasteAction) {
          this.setUndoActions({
            value: this.getFormattedCellValue({
              value, columnType,
            }),
            col,
            row: {
              rowId, scheduleId,
            },
            projectId: route.params.id,
            allowSpinnerOnUpdate,
            disallowLocalRowUpdate,
            action: SCHEDULE_UPDATE_CELL,
          });
        }
        if (useUpdateLocalValue) {
          if (allowSpinnerOnUpdate) {
            spinner(true);
          }
          if (!disallowLocalRowUpdate) {
            const reqValue = ev?.value ? ev.value : ev?.values ? ev.values : null;
            this.updateCellHandle({
              col: columnName, id: rowId, value: reqValue,
            });
          }
        }

        // Get the next row version
        nextRowVersion = getNextRowVersion({
          arr: this.data, rowId,
        });

        // Update the cell temporary version locally
        updateCellVersion({
          rowId, columnName, tempVersion: nextRowVersion,
        });
        // Update the cell version
        this.updateCellHandle({
          col: productHeaders.VERSION,
          id: rowId,
          value: nextRowVersion,
        });

        // Determine the session
        const session = allowSpinnerOnUpdate
          ? `${productHeaders.DEFAULT_FORCE_UPDATE}&${scheduleSessionUuid}`
          : scheduleSessionUuid;

        // Update the cell via an API call
        const { data } = await ScheduleCells.updateCell({
          ...ev,
          col,
          workspaceId: this.activeWorkspaceId,
          row: {
            rowId, scheduleId,
          },
          projectId: route.params.id,
          version: getNextFullRowVersion({
            session, version: nextRowVersion,
          }),
        });

        // Update the cell version locally
        updateCellVersion({
          rowId, columnName, version: nextRowVersion,
        });

        // Check and display a message if proposedCells exist
        const { proposedCells } = data?.response || {
        };

        if (Array.isArray(proposedCells) && proposedCells.length) {
          openSnackBar({
            text: `Please consider updating ${proposedCells}`,
            timeout: -1,
          });
        }
      } catch (err) {
        const isIncorrect = isIncorrectVersion({
          err,
        });

        if (!isIncorrect) {
          handleError(err);
          return;
        }
        const isNeedUpdate = isNeedUpdateIncorrectVersion({
          rowId, columnName, cellsVersions: this.cellsVersions, nextRowVersion,
        });
        // Check last cell version and trigger another update if necessary
        if (isNeedUpdate) {
          this.updateCell({
            ev,
            col,
            rowId,
            canUsePasteAction: false,
            useUpdateLocalValue: false,
            columnName,
            columnType,
            lastFailedCellVersion: lastFailedCellVersion || nextRowVersion,
            isRetryVersionAction: true,
          });
        }
      } finally {
        if (allowSpinnerOnUpdate) this.spinner(false);
      }
    },
    cellsForPasting(columnName) {
      const { selectedCells, copiedContent, mappedDataIds, getIndexOfRow } = this;
      const [firstCellForPasting] = selectedCells;
      const pasteCellsLength = copiedContent?.length;
      const copiedAppropriateContent = Array.isArray(copiedContent)
        && copiedContent.every(item => item?.rowId && item?.col === columnName);
      if (!firstCellForPasting || firstCellForPasting?.col !== columnName
        || !copiedAppropriateContent) {
        return [];
      }
      const firstCellIndexForPasting = getIndexOfRow(firstCellForPasting?.rowId);
      const cellsForPasting = mappedDataIds.filter((item, index) => {
        return index >= firstCellIndexForPasting && index < firstCellIndexForPasting + pasteCellsLength;
      });
      return cellsForPasting;
    },
    isHeaderTypeFormula({ columnType, columnName }) {
      const { isReadOnlyCell } = this;
      return columnType.includes('FORMULA') || isReadOnlyCell(columnName);
    },
    borderedCell({ columnName, currentRowId, columnType }) {
      const {
        isSelectedCell,
        isVersionMode,
        isHeaderTypeFormula,
        allowedForAppropriateRoles,
        canModifyProjectDocuments,
      } = this;
      return columnName !== 'order'
        && !isVersionMode
        && !isHeaderTypeFormula({
          columnName, columnType,
        })
        && (allowedForAppropriateRoles || canModifyProjectDocuments && columnType === 'ASSOCIATED_DOCUMENTS')
        && isSelectedCell({
          columnName, currentRowId,
        });
    },
    async copyContent({ columnType, columnName, isSelected, rowId }) {
      const { data, spinner } = this;
      const selectedCells = isSelected
        ? this.selectedCells
        : [{
          col: columnName,
          rowId,
          index: 0,
        }];
      const sortedSelectedCells = this.lodash.sortBy(selectedCells, ['index']);
      spinner(true);
      const valuesForCopy = sortedSelectedCells.map(({ col, rowId }) => {
        const findCellData = data.find(dataItem => dataItem.id === rowId);
        return findCellData ? {
          value: this.formatValue({
            value: findCellData[col], columnType,
          }),
          rowId,
          col,
        } : undefined;
      });
      spinner(false);
      this.$clipboard(JSON.stringify(valuesForCopy));
      this.openSnackBar({
        text: 'Content has been successfully copied',
        timeout: 2000,
      });
      this.setCopiedContent();
    },
    formatValue({ value, columnType }) {
      if (this.isNumberOrCurrencyField(columnType)) {
        return value ? value.toString() : '0';
      }
      return value === '\b' || value === ' ' ? '' : value;
    },
    isNumberOrCurrencyField(columnType) {
      return ['NUMBER', 'CURRENCY'].includes(columnType);
    },
  },
};
