var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comments-wrapper"},[(_vm.cellPointer)?_c('p',{staticClass:"comments-wrapper__cell-title small-p"},[_c('span',{staticClass:"pointer related-link",attrs:{"data-test":"row_comments_return_to_row_comments"},on:{"click":function($event){return _vm.setCellPointer(undefined)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")]),_vm._v(" Return to row comments ")],1),_c('span',[_vm._v(" "+_vm._s(_vm.cellPointer)+" comments ")])]):_vm._e(),(_vm.data.nextToken)?_c('p',{staticClass:"medium-p mb-0 mx-8 mb-2 text-center show-more-comments",on:{"click":function($event){return _vm.$emit('getRowComments', {
      scanIndexForward: false,
    })}}},[_c('span',{staticClass:"pointer"},[_vm._v(" Show Earlier Comments ")])]):_vm._e(),_vm._l((_vm.filterComments),function(comment,index){return _c('div',{key:comment.id,ref:"commentsRows",refInFor:true,staticClass:"comments-wrapper-border-items comment",class:{
      'mb-0' : index === _vm.data.comments.length -1,
      'comments-wrapper-border-items__edited': _vm.editedRowId === comment.id,
      'comments-wrapper-border-items__scrolled-comment': _vm.scrolledComment === comment.id,
    },attrs:{"id":("commentId_" + (comment.id)),"data-test":"project_comment_block"},on:{"mouseover":function($event){!_vm.$route.query.version ? _vm.hoverRowId=comment.id : _vm.hoverRowId=null},"mouseleave":function($event){_vm.hoverRowId=null}}},[_c('div',{staticClass:"comment-title"},[_c('div',{staticClass:"comment-author"},[_c('AvatarImage',{attrs:{"avatar":_vm.avatars[_vm.getCreatorProp({ comment: comment })],"avatar-key":_vm.getCreatorProp({ comment: comment }),"color":_vm.getCreatorColor({ comment: comment }),"size":25}}),_c('UserInfo',{attrs:{"user-info":comment.creator},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var teamRole = ref.userProjectTeamRole;
            var userNameTitle = ref.userNameTitleAccordingToRole;
return [_c('p',{staticClass:"ma-0 fs-14 font-fam-poppins",attrs:{"data-test":"project_comment_author_name"}},[_vm._v(" "+_vm._s(userNameTitle)+" "),(teamRole)?_c('span',[_vm._v(" "+_vm._s(teamRole)+" ")]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"comment-date"},[_c('p',{staticClass:"small-p title-in-modal mb-0 d-flex",attrs:{"data-test":"project_comments_time"}},[_vm._v(" "+_vm._s(_vm.setDateOfComment(comment))+" "+_vm._s(comment.dateModified ? ' (edited)' : '')+" ")]),(_vm.isShowActionIcon(comment) && comment.commentType !== 'attachment')?_c('v-icon',{attrs:{"color":"black","size":"20"},on:{"click":function($event){return _vm.editRowComment(comment)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.isShowActionIcon(comment))?_c('v-icon',{attrs:{"color":"red","size":"20"},on:{"click":function($event){return _vm.deleteRowComment(comment)}}},[_vm._v(" mdi-trash-can ")]):_vm._e(),(_vm.setCommentIcon(comment))?_c('v-img',{staticClass:"pointer",attrs:{"src":_vm.setCommentIcon(comment),"aspect-ratio":"1","width":"20"}}):_vm._e(),(!_vm.isDisabledComments)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":comment.cellCommentResolved ? 'green' : 'darkGrey',"data-test":"project_comment_resolve_button","size":"20"},on:{"click":function($event){return _vm.$emit('manageCellCommentResolved', comment)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.setResolveTooltip(comment)))])]):_vm._e()],1)]),_c('div',{staticClass:"d-flex flex-column comment-file"},[(comment.cellPointer)?_c('p',{staticClass:"small-p title-in-modal ma-0"},[_vm._v(" Related to "),_c('strong',[_vm._v(_vm._s(comment.cellPointer))])]):_vm._e(),(comment.commentType !== 'attachment')?_c('p',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.formattedTextareaValueForDiv(comment) || ''),expression:"formattedTextareaValueForDiv(comment) || ''"},{name:"linkified",rawName:"v-linkified"}],staticClass:"mb-0",class:{
          'resolved-comment' : comment.cellCommentResolved,
          'resolved-comment__expanded': _vm.expandedComments.includes(comment.id),
        },staticStyle:{"overflow-wrap":"anywhere"},attrs:{"data-test":"project_comment_text"}}):(comment.commentType === 'attachment')?_c('div',{staticClass:"comment-file-container",class:{
          'resolved-comment' : comment.cellCommentResolved,
          'resolved-comment__expanded': _vm.expandedComments.includes(comment.id),
        },attrs:{"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return _vm.attachmentClicked(comment.documentId)}}},[_c('app-dialog',{ref:"dialog",refInFor:true,attrs:{"value":_vm.isDialogOpen,"content-class":"v-dialog__form attachments-documents-wrapper"},on:{"update:value":function($event){_vm.isDialogOpen=$event},"click:outside":_vm.closePreviewDialog},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('v-overlay',{staticClass:"v-overview__preview-image"},[_c('v-icon',{staticClass:"preview-close",on:{"click":_vm.closePreviewDialog}},[_vm._v(" mdi-close ")]),_c('img',{staticClass:"preview-image",attrs:{"src":_vm.attachmentPreviewUrl}}),_c('v-btn',{staticClass:"inverted-btn preview-download",attrs:{"color":"lightGrey","outlined":""},on:{"click":function($event){return _vm.downloadFile(comment.documentId)}}},[_vm._v(" Download ")])],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"comment-file-container__content pointer"},'div',attrs,false),on),[(!comment.thumbnailId || !_vm.getThumbnail(comment.thumbnailId))?_c('img',{attrs:{"src":require("@/assets/icons/file-icon.svg")}}):_vm._e(),(!comment.thumbnailId)?_c('p',{staticClass:"small-p mb-0 overflow-dots",staticStyle:{"max-width":"105px"}},[_vm._v(" "+_vm._s(comment.documentId.split('/')[1] || '')+" ")]):_vm._e(),(comment.thumbnailId)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
                  src: _vm.getThumbnail(comment.thumbnailId),
                  loading: require('@/assets/icons/file-icon.svg'),
                }),expression:"{\n                  src: getThumbnail(comment.thumbnailId),\n                  loading: require('@/assets/icons/file-icon.svg'),\n                }"}],staticClass:"img-screen thumbnail-img"}):_vm._e(),_c('a',{attrs:{"href":("/getFile?fileId=" + (comment.documentId) + "&force_update=true")},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(comment.documentId)}}},[_vm._v(" Download ")])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(comment.documentId.split('/')[1] || '')+" ")])])],1):_vm._e()])])}),(_vm.data.nextTokenForUnread && !_vm.type)?_c('p',{staticClass:"medium-p mb-0 mt-2 mx-8 text-center show-more-comments",on:{"click":function($event){return _vm.$emit('getRowComments', {
      scanIndexForward: true,
    })}}},[_c('span',{staticClass:"pointer"},[_vm._v(" Show the Latest Comments ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }